/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';


#appleid-signin {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 0;
}